<!--========================
Author by: Tith Vannarith
Created on: 03.Sept.2021
Modified:
Company: Soteca
Description:
=========================-->
<template>
  <div>
    <v-container fluid class="px-4 my-4">
      <v-alert
        v-show="warningModalState"
        text
        :type="warningModalColor"
        transition="fade-transition"
      >
        {{ warningMessage }}
      </v-alert>

      <FlowHeader
        :forms="forms"
        :initForm="selectedForm"
        :disableSelectForm="disableSelectForm"
      />

      <v-card outlined class="v-card-drawing-tool mt-4">
        <v-card-title class="listener-header">
          <v-row class="listener-row">
            <v-col
              class="listener-item grey lighten-2"
              v-for="listener in listeners"
              :key="listener.listenerid"
              cols="3"
              draggable
              :data-listener="JSON.stringify(listener)"
              v-on:dragstart="dragToDrawingFlow($event)"
            >
              <span>{{ listener.displayname }}</span>
            </v-col>
          </v-row>
        </v-card-title>

        <v-divider class="my-0"></v-divider>

        <v-sheet
          class="drawing-space grey lighten-4"
          id="flow-drawing-tool"
          v-on:drop="dropToDrawingFlow($event)"
          v-on:dragover="allowDrop($event)"
        ></v-sheet>

        <v-divider class="my-0"></v-divider>

        <v-card-actions>
          <primary-button @click="createStep">
            Save
          </primary-button>
        </v-card-actions>
      </v-card>
    </v-container>

    <v-dialog v-model="showStepSettingModal" max-width="1100" @click:outside="showStepSettingModal = false">
      <StepSettingCard
        :methodKey="selectedMethodKey"
        :currentEntity="currentEntity"
        :default_data="selectedNodeData"
        :selectedTag.async="currentTag"
        :availableTags="available_tags"
        v-on:ok="okButtonStepSettingModalClick"
        v-on:close="showStepSettingModal = false"
      >
      </StepSettingCard>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import Helpers from "../../js/helpers";
import StepSettingCard from "@/components/Flow/StepSettingCard";
import FlowHeader from "@/components/Flow/FlowHeader";
import FlowItem from "@/components/Flow/FlowItem";
import Drawflow from "drawflow";
//import {sample_data_2} from "@/js/sample-flow";
import moment from "moment";

export default {
  name: "CreateFlow",
  components: {
    FlowHeader,
    StepSettingCard,
  },
  data: function () {
    return {
      dblclickDone:false,
      deletedSteps:[],
      currentTag: [],
      currentEntity: "",
      selectedMethodKey: false,
      selectedNodeData: {},
      selectedNodeId: 0,
      selectedNodeData:{},
      warningMessage: "Something went wrong",
      warningModalState: false,
      warningModalColor: "error",
      disableSelectForm: false,
      createdFlows: 0,
      selectedForm: false,
      showStepSettingModal: false,
      selectedConnection: false,
      flowUniqueId: "",
      flowTypes: [],
      listeners: [],
      flowDrawingTool: false,
      showFlowGlobalSettingModal: false,
      forms: [],
      available_tags:[],
    };
  },
  methods: {
    getAvailableTags: async function(){
      const headers = {
        Authorization: "Bearer " + window.$cookies.get("requestToken")
      };
      return await this.$http
        .get("/api/form/"+this.selectedForm.idform+"/available_tags", { headers })
        .then((response) => {
          this.available_tags = response.data
          return true;
        })
        .catch(function (error) {
          console.error(error);
          return false
        });
    },
    showAlertMessage(message = "Something went wrong", color = "error") {
      this.warningMessage = message;
      this.warningModalColor = color;

      this.warningModalState = true;
      setTimeout(() => {
        this.warningModalState = false;
      }, 3000);
    },
    convertNameToKey: function (name) {
      return Helpers.convertNameToKey(name);
    },
    updateShowStepSettingModal: function (status) {
      this.showStepSettingModal = status;
    },
    dragToDrawingFlow: function (ev) {
      ev.dataTransfer.setData(
        "listener",
        ev.target.getAttribute("data-listener")
      );
    },
    dropToDrawingFlow: function (ev) {
      ev.preventDefault();

      const listener = JSON.parse(ev.dataTransfer.getData("listener"));
      if(!listener){
        console.error("Listener not exist")
        return;
      }
      this.addNodeToFlow(listener, ev.clientX, ev.clientY);
    },
    addNodeToFlow: function (listener, pos_x, pos_y) {

      if (!this.selectedForm || !this.selectedForm.idform) {
        this.showAlertMessage("Please select a available form");
        return;
      }

      const allNodes = document.querySelectorAll('div[id^="node-"]');

      let isRoot = true;
      if (allNodes.length > 0) {
        isRoot = false;
      }

      this.flowUniqueId = moment().unix();
      const flowType = Helpers.convertNameToKey(listener.displayname);
      var flowName = "node-" + this.flowUniqueId + "-" + flowType;

      if (this.flowDrawingTool.editor_mode === "fixed") {
        return false;
      }

      pos_x =
        pos_x *
        (this.flowDrawingTool.precanvas.clientWidth /
          (this.flowDrawingTool.precanvas.clientWidth *
            this.flowDrawingTool.zoom)) -
        this.flowDrawingTool.precanvas.getBoundingClientRect().x *
        (this.flowDrawingTool.precanvas.clientWidth /
          (this.flowDrawingTool.precanvas.clientWidth *
            this.flowDrawingTool.zoom));
      pos_y =
        pos_y *
        (this.flowDrawingTool.precanvas.clientHeight /
          (this.flowDrawingTool.precanvas.clientHeight *
            this.flowDrawingTool.zoom)) -
        this.flowDrawingTool.precanvas.getBoundingClientRect().y *
        (this.flowDrawingTool.precanvas.clientHeight /
          (this.flowDrawingTool.precanvas.clientHeight *
            this.flowDrawingTool.zoom));

      const inputs = isRoot ? 0 : listener.next_steps;
      const outputs = listener.next_steps;

      this.selectedNodeId = this.flowDrawingTool.addNode(
        flowName,
        inputs,
        outputs,
        pos_x,
        pos_y,
        flowName,
        {},
        flowType,
        "vue"
      );


      let step = {
        node_id: this.selectedNodeId,
        step_number: "",
        idlistener: listener.idlistener,
        idplatform: listener.idplatform,
        idtag: this.currentTag,
        entity: "",
        entity_placeholder: listener.entity,
        step_type: listener.queuename,
        step_method: "",
        branch: 0,
        failure_behavior: "stop",
      };


      if (listener.methods && typeof listener.methods.methods != "undefined") {
        listener.methods = listener.methods.methods;
      }

      if (listener.methods) {
        const first_key = Object.keys(listener.methods)[0];

        step.step_method = first_key;

        const first_method = listener.methods[first_key];

        let parameter_keys = Object.keys(first_method.parameter_documentation);
        for (let i = 0; i < parameter_keys.length; i++) {
          const parameter_key = parameter_keys[i];
          const parameter_value =
            first_method.parameter_documentation[parameter_key] &&
            first_method.parameter_documentation[parameter_key].list;
          if (parameter_value && parameter_value.length) {
            Object.assign(step, JSON.parse('{"' + parameter_key + '":{}}'));
            step[parameter_key] = parameter_value;
          }
        }
      }
      const dada = {listener,step}
      this.flowDrawingTool.updateNodeDataFromId(this.selectedNodeId, JSON.stringify(dada));

      this.selectedNodeData = dada;

    },
    allowDrop: function (ev) {
      ev.preventDefault();
    },
    // removeConnection: function () {
    //   if (this.selectedConnection) {
    //     this.flowDrawingTool.removeSingleConnection(
    //       this.selectedConnection.output_id,
    //       this.selectedConnection.input_id,
    //       this.selectedConnection.output_class,
    //       this.selectedConnection.input_class
    //     );
    //     this.selectedConnection = false;
    //   }
    // },
    isChildOf: function(child, parent ) {
      var node = child.parentNode;
      //console.log("node ",node)
      while (node != null) {
        if (node == parent) {
          return true;
        }
        node = node.parentNode;
      }
      return false;
    },
    reloadFlowBuilder: function (){
      if (this.listeners) {
        if(!this.flowDrawingTool) {
          const flowDrawingToolId = document.getElementById("flow-drawing-tool");
          this.flowDrawingTool = new Drawflow(flowDrawingToolId, Vue, this);
          this.flowDrawingTool.start();
        }else{
          this.flowDrawingTool.clear();
        }

        this.listeners.forEach((listener) => {
          this.flowDrawingTool.registerNode(
            Helpers.convertNameToKey(listener.displayname),
            FlowItem,
            { html: `<div df-listener df-step>`+listener.displayname+`</div>`},
            {}
          );
        });

        this.flowDrawingTool.on("nodeSelected",(nodeId)=>{
          this.selectedNodeId = nodeId
          const currentNode = this.flowDrawingTool.getNodeFromId(this.selectedNodeId)
          this.selectedNodeData =
            typeof currentNode.data == "string" ? JSON.parse(currentNode.data) : currentNode.data;
        });

        this.flowDrawingTool.on("contextmenu",(ev)=>{
          const id_att =
            ev.target.parentElement.parentElement.getAttribute("id");
          if (id_att && typeof id_att != "undefined") {
            const nodId = id_att.replaceAll("node-", "");
            if(nodId && nodId != ""){
              this.selectedNodeId = nodId;
              const currentNode = this.flowDrawingTool.getNodeFromId(this.selectedNodeId)
              this.selectedNodeData =
                typeof currentNode.data == "string" ? JSON.parse(currentNode.data) : currentNode.data;
            }
          }
        });

        this.flowDrawingTool.on("click", (ev) => {
          let target_class = ev.target.getAttribute("class");
          let target_class_arr = [];
          if (target_class && target_class != "") {
            target_class_arr = target_class.split(" ");
          }
          let settingNode = false
          const settingNodes = document.getElementsByClassName('setting')
          if(settingNodes) settingNode = settingNodes[0]

          const isSettingButton = this.isChildOf(ev.target,settingNode) || target_class_arr.indexOf("setting") > -1

          if (isSettingButton) {
            ev.preventDefault();
            const id_att =
              ev.target.parentElement.parentElement.parentElement.getAttribute(
                "id"
              );
            if (id_att && typeof id_att != "undefined") {
              const nodId = id_att.replaceAll("node-", "");
              this.fnShowFlowSettingModal(nodId);
            }
          }
        });

        this.flowDrawingTool.on("dblclick", async (ev) => {
          ev.preventDefault();
          if(!this.dblclickDone && this.flowDrawingTool.node_selected) {
            this.dblclickDone = true;
            console.log('flow-item dbclick ', this.flowDrawingTool.node_selected)
            const id_att = this.flowDrawingTool.node_selected.id
            if (id_att && typeof id_att != "undefined") {
              const nodId = id_att.replaceAll("node-", "");
              this.fnShowFlowSettingModal(nodId);
            }
          }
        });

        // this.flowDrawingTool.on("keydown", (ev) => {
        //   if (ev.code === "Backspace") {
        //     this.removeConnection();
        //   }
        // });

        // this.flowDrawingTool.on("nodeCreated", (nodeId) => {
        //   this.selectedNodeId = nodeId
        // });

        this.flowDrawingTool.on("nodeRemoved", (nodeId) => {
          const deletedStep =  this.selectedNodeData.step;
          console.log(deletedStep.node_id+" === "+nodeId)
          if(deletedStep.node_id == nodeId) {
            Object.assign(deletedStep, {delete: true});
            this.deletedSteps.push(deletedStep);
            console.log(this.deletedSteps)
          }
        });

        // this.flowDrawingTool.on("connectionSelected", (connection) => {
        //   this.selectedConnection = connection;
        // });
        // this.flowDrawingTool.on("connectionUnselected", (connection) => {
        //   this.selectedConnection = false;
        // });
        // this.flowDrawingTool.on("connectionCreated", (connection) => {
        //   const output = this.flowDrawingTool.getNodeFromId(
        //     connection.output_id
        //   );
        //   if (output.outputs.output_1.connections.length > 1) {
        //     this.showAlertMessage(`Only 1 connection be allowed`);
        //     this.flowDrawingTool.removeSingleConnection(
        //       connection.output_id,
        //       connection.input_id,
        //       connection.output_class,
        //       connection.input_class
        //     );
        //   }
        // });
      }
    },
    flowSettingInit: async function () {
      await this.getForms();
      await this.getAvailableTags();
      await this.getListeners();
      this.reloadFlowBuilder();
      this.getCurrentFlow();
    },
    getForms: async function () {
      const idform = this.$route.params.idform;
      console.log("idform ",idform)
      const headers = {
        Authorization: "Bearer " + window.$cookies.get("requestToken")
      };
      return await this.$http
        .get("/api/form/"+idform, { headers })
        .then((response) => {
          this.forms = [response.data];
          this.selectedForm = response.data
          setTimeout(()=>{
            this.disableSelectForm = true;
          },500)
          return true;
        })
        .catch(function (error) {
          console.error(error);
          return false
        });
    },
    getListeners: async function () {
      const headers = {
        Authorization: "Bearer " + window.$cookies.get("requestToken"),
      };
      if(!this.selectedForm) return;
      console.log("this.selectedForm ",this.selectedForm)
      return await this.$http
        .get("/api/listener?idform="+this.selectedForm.idform, { headers })
        .then((response) => {
          if (Array.isArray(response.data)) {
            this.listeners = response.data;
          } else {
            this.listeners = [response.data];
          }
        })
        .catch(function (error) {
          console.error(error);
          return false;
        });
    },
    createOrUpdateFlowObject: function (flowId, listenerMethod) {
      if (!this.steps.hasOwnProperty(flowId)) {
        Object.assign(this.steps, JSON.parse('{"' + flowId + '":{}}'));
      }
      this.steps[flowId] = listenerMethod;
    },
    okButtonStepSettingModalClick: function (step, listener) {
      const selectedNode = this.flowDrawingTool.getNodeFromId(
        this.selectedNodeId
      );
      let nodeData = selectedNode.data;
      if(typeof nodeData == "string"){
        nodeData = JSON.parse(nodeData)
      }

      step.node_id = nodeData.step.node_id;
      step.idstep = nodeData.step.idstep;

      nodeData.step = step;
      nodeData.listener = listener;

      console.log("After Modal ",step)

      this.selectedMethodKey = false;
      this.selectedNodeData = false;
      this.showStepSettingModal = false;
      this.flowDrawingTool.updateNodeDataFromId(this.selectedNodeId, JSON.stringify(nodeData));

    },
    createStep: function () {

      const This = this

      const headers = {
        Authorization: "Bearer " + window.$cookies.get("requestToken"),
      };
      let data = {
        transaction_begun: false,
        steps: [],
      };

      if(!This.selectedForm || !This.selectedForm.idform){
        this.showAlertMessage("a form doesn't selected")
        return;
      }

      const nodes = document.querySelectorAll('[id^="node-"]');
      let rootflow = false;
      let flows = {};
      let howManyRootFlow = 0;
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        if (node.id && typeof node.id != "undefined") {
          const flow_id = node.id.replaceAll("node-", "");
          const __flow = This.flowDrawingTool.getNodeFromId(flow_id);
          console.log("__flow ",__flow)
          const flow = JSON.parse('{"' + flow_id + '":{}}');
          flow[flow_id] = __flow;
          Object.assign(flows, flow);

          // Root Flow found [first condition]
          if(!(Object.keys(__flow.inputs).length)){
            rootflow = __flow;
            howManyRootFlow++;
            //console.log("rootflow [1] ", rootflow)
            //return;
          }

          if(!rootflow) {
            // Other process to find root flow
            const flow_keys = Object.keys(__flow.inputs);
            //console.log("flow_keys ", flow_keys)

            // Root Flow found [second condition]
            if (!flow_keys.length) {
              rootflow = __flow;
              howManyRootFlow++;
            }
            if(!rootflow) {
              // Root Flow found [third condition]
              flow_keys.forEach((key) => {
                const input = __flow.inputs[key];
                if (!input.connections) {
                  rootflow = __flow;
                  howManyRootFlow++;
                  return;
                }
              });
            }
          }
        }
      }

      if (rootflow) {
        This.recusiveFlowDefine(data, flows, rootflow);
        // Get keys
        const step_keys = Object.keys(data.steps);

        // // Validation
        // for (let i = 0; i < step_keys.length; i++) {
        //   const step_key = step_keys[i];
        //   if(!data.steps[step_key].tagname || data.steps[step_key].tagname == "" || data.steps[step_key].tagname == null){
        //     This.showAlertMessage("Tag or listener is require");
        //     return;
        //   }
        // }

        let step_number = 0;
        for (let i = 0; i < step_keys.length; i++) {
          const step_key = step_keys[i];
          data.steps[step_key].step_number = step_number;

          if(data.steps[step_key].idtag && typeof data.steps[step_key].idtag !== "string") {
            console.log(data.steps[step_key])
            console.log("\n")
            const current_listener = this.listeners.find((l)=>{
              return l.idlistener = data.steps[step_key].idlistener
            })
            data.steps[step_key].idtag = data.steps[step_key].idtag[current_listener.idplatform].idtag;
          }

          //console.log("KEY ", step_keys[i]);
          if(i < step_keys.length - 1){
            data.steps[step_key].next_step1 = i + 1;
          }
          console.log("VALUE ", data.steps[step_key]);
          step_number++;
        }

        if(this.deletedSteps.length)
        data.steps = data.steps.concat(this.deletedSteps);

        //console.log(JSON.stringify(this.deletedSteps));return;

        This.$http
          .patch("/api/form/" + This.selectedForm.idform + "/steps/bulk", data, { headers })
          .then((response) => {
            if (response.status == 200)
              This.showAlertMessage("Step created successfully", "success");
            else This.showAlertMessage();
          })
          .catch(function (error) {
            console.error(error.response.data.message);
            This.showAlertMessage(error.response.data.message);
          });
      }else{
        console.error("Root flow not found ", nodes)
      }

    },
    recusiveFlowDefine: function (ref, flows, flow) {

      let nodeData = flow.data;
      if(typeof nodeData == "string"){
        nodeData = JSON.parse(nodeData)
      }
      ref.steps.push(nodeData.step);

      const flow_keys = Object.keys(flow.outputs);
      flow_keys.forEach((key) => {
        const output = flow.outputs[key];
        output.connections.forEach((connection) => {
          const current_flow = flows[connection.node];
          this.recusiveFlowDefine(ref, flows, current_flow);
        });
      });
    },
    fnShowFlowSettingModal: async function (nodId) {

      this.selectedNodeId = nodId;

      let selectedNode = this.flowDrawingTool.getNodeFromId(
        this.selectedNodeId
      );

      let nodeData = selectedNode.data;
      if(typeof nodeData == "string"){
        nodeData = JSON.parse(nodeData)
      }

      if(!nodeData.step.edited) {
        const step_from_remote = await this.getStep(nodeData.step.idstep)
        nodeData.step = step_from_remote;
        nodeData.step.idtag = this.available_tags.find((t)=>{
          return t[nodeData.listener.idplatform].idtag == step_from_remote.idtag;
        });

        this.currentTag = nodeData.step.idtag;

        console.log("this.currentTag ",this.currentTag)

        this.flowDrawingTool.updateNodeDataFromId(this.selectedNodeId, JSON.stringify(nodeData));
      }

      this.selectedMethodKey = nodeData.step.step_method;
      //console.log("selectedMethodKey",this.selectedMethodKey)

      this.currentEntity = nodeData.step.entity

      this.selectedNodeData = nodeData;

      console.log("Befor Modal ",this.selectedNodeData)
      if (this.selectedNodeData) {
        setTimeout(() => {
          this.showStepSettingModal = true;
          this.dblclickDone = false;
        }, 300);
      }
    },
    getCurrentFlow:function () {
      const idform = this.$route.params.idform
      if(this.forms){
        this.forms.forEach((form) => {
          if(form.idform === idform){
            this.selectedForm = form;
            return;
          }
        });
      }

      const This = this;
      This.$store.commit('set', ['modalSpinnerText','Loading...']);
      This.$store.commit('toggle', 'showHideModalSpinner');
      this.$http
        .get('/api/form/'+idform+'/steps' ,{
          headers: {
            'Authorization': 'Bearer ' + this.$cookies.get("requestToken"),
            'Include-Form-String': 'true'
          }
        })
        .then( response => {
          let steps = response.data;
          console.log("steps len ",steps.length);
          if(steps){
            steps = steps.sort((a, b) => a.step_number - b.step_number );
            let pos_x = 10
            let pos_y = 10
            steps.forEach((stp,index)=>{
              if (this.flowDrawingTool.editor_mode === "fixed") {
                return false;
              }

              let isRoot = false;
              if (stp.step_number === 0) {
                isRoot = true;
              }

              const listener = this.listeners.find((l)=>{
                return l.idlistener == stp.idlistener
              });

              if(!listener) {
                console.log("stp ",stp)
                return;
              }

              this.flowUniqueId = moment().unix();
              const flowType = Helpers.convertNameToKey(listener.displayname);
              var flowName = "node-" + this.flowUniqueId + "-" + flowType;

              let _pos_x = pos_x
              let _pos_y = pos_y

              const inputs = isRoot ? 0 : 1;
              const outputs = 1;

              this.selectedNodeId = this.flowDrawingTool.addNode(
                flowName,
                inputs,
                outputs,
                _pos_x,
                _pos_y,
                flowName,
                {},
                flowType,
                "vue"
              );

              let step = stp;

              step.node_id = this.selectedNodeId

              if (listener.methods && !step.step_method) {
                const first_key = Object.keys(listener.methods)[0];

                step.step_method = first_key;

                const first_method = listener.methods[first_key];

                if(typeof first_method.parameter_documentation !== "undefined") {
                  let parameter_keys = Object.keys(first_method.parameter_documentation);
                  for (let i = 0; i < parameter_keys.length; i++) {
                    const parameter_key = parameter_keys[i];
                    const parameter_value =
                      first_method.parameter_documentation[parameter_key] &&
                      first_method.parameter_documentation[parameter_key].list;
                    if (parameter_value && parameter_value.length) {
                      Object.assign(step, JSON.parse('{"' + parameter_key + '":{}}'));
                      step[parameter_key] = parameter_value;
                    }
                  }
                }
              }

              this.flowDrawingTool.updateNodeDataFromId(this.selectedNodeId, JSON.stringify({listener,step}));

              steps[index] = step;

              pos_x += 200
              pos_y += 60

            });
            steps.forEach((stp,index)=>{
              const output_id = index + 1
              const input_id = output_id + 1
              this.flowDrawingTool.addConnection(output_id, input_id, "output_1", "input_1")
            });
          }
          This.$store.commit('toggle', 'showHideModalSpinner');
        })
        .catch(function (error) {
          console.error(error);
          This.$store.commit('toggle', 'showHideModalSpinner');
        });

    },
    getStep: async function(idstep){
      const params ={
        idform: this.selectedForm.idform
      }
      const headers = {
        Authorization: "Bearer " + window.$cookies.get("requestToken"),
      };
      return await this.$http
        .get("/api/step/"+idstep, { headers,params })
        .then((response) => {
          return response.data
        })
        .catch(function (error) {
          console.error(error);
          return false
        });
    },
  },
  computed: {},
  mounted() {
    this.flowSettingInit();
  },
};
</script>

<style scoped>
.drawing-space {
  height: calc(100vh - 400px);
}
</style>
